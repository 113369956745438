<template>
  <section>  
    <b-row class="match-height">
      <div class="col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Registrations ({{ dataCount }}) 
            </h4>
            <!-- <form>
              <div class="form-group">
                <input
                  v-model="searchParam"
                  name="search"
                  placeholder="Search"
                  class="form-control"
                >
              </div>
            </form> -->
              <div class="export-form-wrapper">
                <div class="form-group">
                  <label>Start Date</label>
                  <input
                    type="datetime-local"
                    v-model="payload.start_date"
                    name="start_date"
                    placeholder="Start"
                    class="form-control mr-2"
                  >   
                </div>             
                <div class="form-group">
                  <label>End Date</label>
                  <input
                    type="datetime-local"
                    v-model="payload.end_date"
                    name="end_date"
                    placeholder="Start"
                    class="form-control mr-2"
                  >   
                </div>             
                <div class="form-group">
                  <label>Program</label>
                  <input
                    type="text"
                    v-model="payload.program"
                    name="program"
                    placeholder="Program"
                    class="form-control mr-2"
                  >   
                </div>             
                <b-button
                  @click="exportData"
                  variant="primary"
                >
                  Export Data
                </b-button>  
              </div>            
          </div>
          <div class="card-datatable">
            <div
              v-if="dataLoading"
              class="overlay"
            >
              Please wait ....
            </div>
            <div v-else class="datatables-ajax table table-responsive ">
              <table >
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Age</th>
                    <th>Employment Status</th>
                    <th>Qualification</th>
                    <th>Trade of interest</th>
                    <th>Location</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>SN</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Age</th>
                    <th>Employment Status</th>
                    <th>Qualification</th>
                    <th>Trade of interest</th>
                    <th>Location</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr
                    v-for="(event, index) in bhvEvents"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{event.name}}
                    </td>
                    <td>
                      {{ event.email }}
                    </td>
                    <td>{{ event.phone_number }}</td>
                    <td>{{ event.age }}</td>
                    <td>{{ event.employment_status }}</td>
                    <td>{{ event.qualification }}</td>
                    <td>{{ event.trade_of_interest }}</td>
                    <td>{{ event.location }}</td>
                    <td>
                      <b-button-group>
                        <button
                          v-b-modal.event-edit-modal
                          class="btn btn-primary btn-sm"
                          size="sm"
                          @click="selectEvent(event)"
                        >
                        <feather-icon
                          class="mr-25"
                          icon="EyeIcon"
                          size="15"
                        />
                        </button>

                        <b-button 
                          variant="danger" 
                          size="sm"
                          @click="deleteEvent(event)"
                        >
                        <feather-icon
                          class="mr-25"
                          icon="DeleteIcon"
                          size="15"
                        />
                        </b-button>
                      </b-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>            
          </div>
        </div>
      </div>
    </b-row> 
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in meta.links" :key="link.id">
            <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
          </li>
        </ul>
      </nav>         
    <b-modal
      id="event-edit-modal"
      content-class="shadow"
      :title="`Edit ${selected.name}`"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <b-form>
        <b-row>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Name"
            >
              <b-form-input
                type="text"
                name="name"
                aria-label="name"
                v-model="selected.name"
                placeholder="name"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Age"
            >
              <b-form-input
                type="text"
                name="age"
                aria-label="age"
                v-model="selected.age"
                placeholder="age"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Gender"
            >
              <b-form-input
                name="gender"
                aria-label="Gender"
                v-model="selected.gender"
                placeholder="Gender"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Email"
            >
              <b-form-input
                name="email"
                aria-label="Email"
                v-model="selected.email"
                placeholder="Email"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Location"
            >
              <b-form-input
                name="location"
                aria-label="Location"
                v-model="selected.location"
                placeholder="Location"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Employment Status"
            >
              <b-form-input
                name="employment_status"
                aria-label="Employment _status"
                v-model="selected.employment_status"
                placeholder="Employment status"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Qualification"
            >
              <b-form-input
                name="qualification"
                aria-label="Qualification"
                v-model="selected.qualification"
                placeholder="Qualification"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Trade of Interest"
            >
              <b-form-input
                name="trade_of_interest"
                aria-label="Trade of Interest"
                v-model="selected.trade_of_interest"
                placeholder="Trade of Interest"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Program"
            >
              <b-form-input
                name="program"
                aria-label="Program"
                v-model="selected.program"
                placeholder="Program"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Employment interest"
            >
              <b-form-input
                name="long_term_employment_interest"
                aria-label="Long term Employment Interest"
                v-model="selected.long_term_employment_interest"
                placeholder="Long term Employment Interest"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="How you know about us"
            >
              <b-form-input
                name="about_us"
                aria-label="How you know about us"
                v-model="selected.about_us"
                placeholder="How you know about us"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Reason for Interest"
            >
              <b-form-textarea
                name="body"
                aria-label="Reason for Interest"
                v-model="selected.reason_for_interest"
                placeholder="Reason for Interest"
                readonly
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Why scholarship"
            >
              <b-form-textarea
                name="why_scolarship"
                aria-label="Why Shcolarship"
                v-model="selected.why_scholarship"
                placeholder="Why Shcolarship"
                readonly
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <!-- <b-col md="12" lg="12" class="text-center">
            <b-button variant="primary" size="lg" @click="updateEvent">
              Save 
              <b-spinner
                variant="white"
                small
                class="ml-1"
                v-show="loading"
              />
            </b-button>
          </b-col> -->
        </b-row>
      </b-form>
    </b-modal>       
  </section>
</template>

<script>
import {
  BRow, BMedia, BAvatar, BCard, BCol, BCardBody, 
  BMediaAside, BMediaBody,BCardText, BButtonGroup,
  BButton, BModal, BForm, BFormGroup, BImg, BFormRadioGroup, BFormRadio,
  BFormDatepicker, BFormFile,BFormInput, BFormTextarea, BSpinner,
} from 'bootstrap-vue'
import router from '@/router'


export default {
  components: {
    BRow,
    BSpinner,
    BImg,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BFormFile,
    BFormDatepicker,
    BButtonGroup,
    BForm,
    BFormGroup,
    BModal,
    BButton,
    BMedia,
    BAvatar,
    BCard,
    BCol,
    BCardBody, BMediaAside, BMediaBody,BCardText,
  },
  data() {
    return {
      form: new FormData(),
      payload: {},
      dataLoading: false,
      details: {},
      selected: {},
      data: {},
      bhvEvents: [],
      meta: {},
      links: {},
      url: '',
      loading: false,
      searchParam: '',
      cancelled_withdrawals: 0,
      failed_withdrawals: 0,
      monthly: 0,
      total_widthrawals: 0,
      dataCount: 0,
    }
  },
  computed: {
    eventList() {
      return this.bhvEvents.filter(item => item.title.includes(this.searchParam) || item.title.toLowerCase().includes(this.searchParam))
    },
  },
  mounted() {
    this.url = `${this.$url}/events/${router.currentRoute.params.id}/list`
    this.getRegistrations()
  },
  methods: {
    prepareFile(event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) return
      this.form.append('cover_photo', files[0])
    }, 
    deleteEvent(event) {
      this.$swal({
        title: `Delete ${event.title}`,
        html: '<div class=" text-center mt-4"><h4 class="text-danger mb-3">Are you sure you want to delete this ?</h4></div>',
        confirmButtonText: 'Yes, delete it!',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary text-primary ml-1',
        },        
      }).then(result => {
        if (result.value) {
          this.deleteSelected(event.id)
        } 
      })
    },    
    deleteSelected(id) {
      this.$http.delete(`${this.$url}/events/registration/${id}`)
        .then(response => {
          console.log(response)
          if(response.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            .then(res => {
              // window.location.reload()
              this.bhvEvents = this.bhvEvents.filter(e => e.id !== id)
            })
          }
        })
        .catch( err => {
          if(err.response) {
            let message
            if(err.response.status != 200) {
              if(err.response.data.errors) {
                let errors = err.response.data.errors
                let errorList = Object.values(errors)
                errorList.map(msg => {
                    message = msg
                })
              }
              this.$swal({
                title: 'Error',
                text: err.response.data.message || message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-secondary',
                },
              })  
            }        
          }          

        })
    },     
    selectEvent(item) {
      this.selected = item
    },   
    paginate(url) {
      this.url = url
      this.getRegistrations()
    },  
    updateEvent() {
      this.loading = true
      this.form.append("title", this.selected.title)
      this.form.append("body", this.selected.body)
      this.form.append("status", this.selected.status)
      this.form.append("start_date", this.selected.start_date)
      this.form.append("end_date", this.selected.end_date)
      this.$http.post(`${this.$url}/events/${this.selected.id}`, this.form)
        .then(response => {
          console.log("categories", response.data)
          if(response.data.status) {
            this.selected = response.data.data 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })            
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },        
    addEvent() {
      this.loading = true
      this.form.append("title", this.details.title)
      this.form.append("body", this.details.body)
      this.form.append("status", this.details.status)
      this.form.append("start_date", this.details.start_date)
      this.form.append("end_date", this.details.end_date)
      this.$http.post(`${this.$url}/events`, this.form)
        .then(response => {
          if(response.data.status) {
            this.bhvEvents.push(response.data.data) 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })            
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },        
    getRegistrations() {
      this.dataLoading = true
      this.$http.get(`${this.url}`)
        .then(response => {
          if(response.data.status) {
            console.log(response)
            this.bhvEvents = response.data.data.data
            this.links = response.data.data.links
            this.meta = response.data.data.meta ?? []
            this.dataCount = response.data.count
          }
        })
        .finally(() => this.dataLoading = false)
    },
    exportData() {
      this.payload['id'] = router.currentRoute.params.id
      this.$http.post(`${this.$url}/events/registration/list/export`, this.payload,
          {responseType: 'arraybuffer'})
      .then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Registrations_'+this.payload.start_date+'_to_'+this.payload.end_date+'.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
    }    
  },
}
</script>

<style lang="scss">
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.2);
    font-size: 20px;
    color: #fff;
  }
  .export-form-wrapper {
    div {
      display: inline-block;
      margin-right: 8px;
    }
  }
</style>
